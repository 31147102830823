import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import get from "lodash.get";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "404-bg.jpg" }) {
        bgDesktopWide: childImageSharp {
          resize(width: 1920, quality: 90, cropFocus: CENTER) {
            src
          }
        }
        bgDesktop: childImageSharp {
          resize(width: 1200, quality: 90, cropFocus: CENTER) {
            src
          }
        }
        bgTablet: childImageSharp {
          resize(width: 992, quality: 90, cropFocus: CENTER) {
            src
          }
        }
        bgMobile: childImageSharp {
          resize(width: 768, height: 850, quality: 90, cropFocus: CENTER) {
            src
          }
        }
        bgMobileXS: childImageSharp {
          resize(width: 576, height: 850, quality: 90, cropFocus: CENTER) {
            src
          }
        }
      }
    }
  `);
  const title = "Page Not found";
  const desktopWide = get(data, "file.bgDesktopWide.resize.src");
  const desktop = get(data, "file.bgDesktop.resize.src");
  const tablet = get(data, "file.bgTablet.resize.src");
  const mobile = get(data, "file.bgMobile.resize.src");
  const mobileXS = get(data, "file.bgMobileXS.resize.src");
  const heading = "404";
  const subheading = "Page Not Found";
  const description = (
    <>
      It seems we can’t find the page you are looking for.
      <br /> Go Back To&nbsp;
      <a href="/">Homepage</a>
    </>
  );

  return (
    <Layout>
      <SEO
        title={title}
        pathname="/404"
        keywords={[
          "singapore",
          "hire",
          "tech",
          "foreign",
          "talent",
          "offshore",
          "outsource",
          "programmer",
          "software",
          "developer"
        ]}
      />
      <section className="bg-cover bg-center bg-no-repeat py-5 hero">
        <div className="container py-5 mb-4 text-center">
          <h1 className="display-404">{heading}</h1>
          <h2 className="h3 text-white">{subheading}</h2>
          <p className="text-white">{description}</p>
        </div>
      </section>
      <style jsx="true">{`
        .hero {
          background-image: url(${desktopWide});
        }
        @media (min-width: 992px) and (max-width: 1200px) {
          .hero {
            background-image: url(${desktop});
          }
        }
        @media (min-width: 768px) and (max-width: 992px) {
          .hero {
            background-image: url(${tablet});
          }
        }
        @media (min-width: 576px) and (max-width: 768px) {
          .hero {
            background-image: url(${mobile});
          }
        }
        @media (max-width: 576px) {
          .hero {
            background-image: url(${mobileXS});
          }
        }
      `}</style>
    </Layout>
  );
};

export default NotFound;
